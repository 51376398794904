import React, { Fragment } from 'react'
import { Text, TextProps } from '@chakra-ui/react'
import useDeliveryDate from '@common/hooks/use-delivery-date'

interface PropsType {
  order_at?: Date
  delivery_period?: any
  textProps: TextProps
}

const DeliveryDate = ({ order_at, delivery_period, textProps }: PropsType) => {
  const { deliveryDate } = useDeliveryDate({
    date: order_at,
    delivery_period
  })
  const props = { ...textProps }

  return (
    <Fragment>
      <Text {...props}>{deliveryDate} </Text>
    </Fragment>
  )
}

export default DeliveryDate
