import { ProductVariant } from '@medusajs/medusa'

export const canBuy = (variant: Omit<ProductVariant, 'beforeInsert'>) => {
  return variant.inventory_quantity > 0 || variant.allow_backorder === true
}

export const canBuys = (variants: Omit<ProductVariant, 'beforeInsert'>[]) => {
  const out_of_stocks: boolean[] = []

  for (let i = 0; i < variants.length; i++) {
    const inStock = canBuy(variants[i])

    if (!inStock) {
      out_of_stocks.push(true)
    } else {
      out_of_stocks.push(false)
    }
  }

  return out_of_stocks.some((o: boolean) => o === false)
}

export const canPreOrder = (variant: any) => {
  return variant.inventory_quantity > 0 || variant.allow_pre_order === true
}

export const canPreOrders = (
  variants: Omit<ProductVariant, 'beforeInsert'>[]
) => {
  const out_of_stocks: boolean[] = []

  for (let i = 0; i < variants.length; i++) {
    const inStock = canPreOrder(variants[i])

    if (!inStock) {
      out_of_stocks.push(true)
    } else {
      out_of_stocks.push(false)
    }
  }

  return out_of_stocks.some((o: boolean) => o === false)
}
