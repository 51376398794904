import { useToast, UseToastOptions } from '@chakra-ui/react'

export const useAlert = () => {
  const toast = useToast()

  const defaultToastConfig: UseToastOptions = {
    duration: 5000,
    isClosable: true,
    position: 'top-right'
  }

  const successToast = (options: UseToastOptions) => {
    toast({
      ...defaultToastConfig,
      ...options,
      containerStyle: {
        bg: 'green',
        borderRadius: '5px'
      },
      status: 'success'
    })
  }

  const errorToast = (options: UseToastOptions) => {
    toast({
      ...defaultToastConfig,
      ...options,
      containerStyle: {
        bg: 'red',
        borderRadius: '5px'
      },
      status: 'error'
    })
  }

  const warningToast = (options: UseToastOptions) => {
    toast({
      ...defaultToastConfig,
      ...options,
      containerStyle: {
        bg: 'yellow',
        borderRadius: '5px'
      },
      status: 'warning'
    })
  }

  return { successToast, errorToast, warningToast }
}
