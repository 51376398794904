import { ICustomConfig } from '@common/interface/custom-config'
import { listConfigs } from '@lib/util/custom-config-handler'
import { useQuery } from '@tanstack/react-query'
import { useCart } from 'medusa-react'
import { useCallback, useEffect, useState } from 'react'

const useCustomConfig = () => {
  const [configs, setConfigs] = useState<ICustomConfig[]>([])
  const { cart } = useCart()

  const { data } = useQuery(['list-configs'], () => listConfigs())

  useEffect(() => {
    if (data && Object.keys(data.data || {}).length) {
      const configsData: ICustomConfig[] = []
      for (const key in data.data) {
        configsData.push({
          id: 'id' + key,
          field_name: key,
          region: data.data[key].region,
          value: data.data[key].value
        })
      }
      setConfigs(configsData)
    }
  }, [data])

  const getConfig = useCallback(
    ({ field_name }: { field_name: string }) => {
      if (configs?.length) {
        const configFilter = configs.filter((c) => c.field_name === field_name)

        const configAll = configFilter.find((c) => c.region === 'all')

        const configRegion = configFilter.find(
          (c) => c.region === cart?.region?.id
        )

        if (configRegion) {
          return configRegion
        }

        return configAll
      }
      return undefined
    },
    [configs, cart]
  )

  return { configs, getConfig }
}

export default useCustomConfig
