import { useMemo } from 'react'
import { listPublicHolidays } from '@lib/util/public-holiday-handler'
import { useQuery } from '@tanstack/react-query'
import { IPublicHoliday } from '@common/interface/public-holiday'
import moment from 'moment'
import useCustomConfig from './use-custom-config'
import { useCart } from 'medusa-react'

const mapToDayPublicHolidays = (
  publicHolidays: IPublicHoliday[],
  year: number
): IPublicHoliday[] => {
  return publicHolidays.filter(
    (publicHoliday) =>
      new Date(publicHoliday.from).getFullYear() === year &&
      new Date(publicHoliday.to).getFullYear() === year
  )
}

const isWeekend = (time: number): boolean => {
  const isSatSun = ['Saturday', 'Sunday'].includes(moment(time).format('dddd'))

  return isSatSun
}

const checkIsPublicHoliday = (
  startDate: Date,
  publicHolidays: IPublicHoliday[],
  workingsDay: number
): boolean => {
  for (let i = 0; i < workingsDay; i++) {
    const time =
      new Date(startDate.toISOString().substring(0, 10)).getTime() +
      i * 24 * 60 * 60 * 1000

    const publicHolidaysFilter = publicHolidays.filter(
      (publicHoliday) =>
        time >= new Date(publicHoliday.from).getTime() &&
        time <= new Date(publicHoliday.to).getTime()
    )

    if (publicHolidaysFilter.length > 0) {
      return true
    }
  }

  return false
}

const countPublicHolidayWokingDays = (
  startDate: Date,
  publicHolidays: IPublicHoliday[],
  workingsDay: number
): number => {
  let count = 0

  let wds = workingsDay

  for (let i = 0; i < wds; i++) {
    const time =
      new Date(startDate.toISOString().substring(0, 10)).getTime() +
      i * 24 * 60 * 60 * 1000

    const publicHolidaysFilter = publicHolidays.filter(
      (publicHoliday) =>
        time >= new Date(publicHoliday.from).getTime() &&
        time <= new Date(publicHoliday.to).getTime()
    )

    const isSatSun = isWeekend(time)

    if (publicHolidaysFilter.length > 0 && isSatSun) {
      count += 1
      wds += 1
    } else if (publicHolidaysFilter.length > 0 && !isSatSun) {
      count += 1
      wds += 1
    } else if (publicHolidaysFilter.length === 0 && isSatSun) {
      count += 1
      wds += 1
    }
  }

  return count
}

const FORMAT = 'DD/MM/YYYY'

const useDeliveryDate = ({
  date,
  delivery_period,
  format
}: {
  date?: Date
  delivery_period?: any
  format?: string
}) => {
  const { data } = useQuery(['list-public-holidays'], () =>
    listPublicHolidays()
  )

  const { getConfig } = useCustomConfig()
  const { cart } = useCart()

  const deliveryPeriodConfig = getConfig({
    field_name:
      process.env.NEXT_PUBLIC_DELIVERY_DATE_CONFIG_FIELD_NAME ||
      'delivery_period'
  })

  const purchaseBeforeConfig = getConfig({
    field_name: 'purchase_before'
  })

  const startDateTime = useMemo(() => {
    return date ? new Date(date).getTime() : Date.now()
  }, [date])

  const countPublicHoliday = useMemo(() => {
    const workingDays = delivery_period
      ? parseInt(delivery_period)
      : deliveryPeriodConfig
        ? parseInt(deliveryPeriodConfig.value)
        : 0

    const today = date ? new Date(date) : new Date()

    if (data?.data) {
      const publicHolidayRegion = data.data?.filter(
        (publicHoliday) => publicHoliday.region === cart?.region?.id
      )

      const publicHolidayRegionAll = data.data?.filter(
        (publicHoliday) => publicHoliday.region === 'all'
      )

      const regionDays = mapToDayPublicHolidays(
        publicHolidayRegion,
        new Date(today).getFullYear()
      )

      const regionAllDays = mapToDayPublicHolidays(
        publicHolidayRegionAll,
        new Date(today).getFullYear()
      )

      const publicHolidaysRegion = countPublicHolidayWokingDays(
        today,
        regionDays,
        workingDays
      )

      const publicHolidaysRegionAll = countPublicHolidayWokingDays(
        today,
        regionAllDays,
        workingDays
      )

      if (publicHolidaysRegion !== 0 && publicHolidayRegion.length > 0) {
        return publicHolidaysRegion
      }

      return publicHolidaysRegionAll
    }

    return 0
  }, [
    deliveryPeriodConfig,
    delivery_period,
    data,
    date,
    cart,
    purchaseBeforeConfig
  ])

  const countWorkingDays = useMemo(() => {
    let workingDays = delivery_period
      ? parseInt(delivery_period)
      : deliveryPeriodConfig
        ? parseInt(deliveryPeriodConfig.value)
        : 0

    workingDays = !Number.isNaN(workingDays) ? workingDays : 0

    workingDays = workingDays + countPublicHoliday

    if (purchaseBeforeConfig) {
      const hours = date ? new Date(date).getHours() : new Date().getHours()

      if (
        hours > parseInt(purchaseBeforeConfig.value.substring(0, 2) ?? '24')
      ) {
        workingDays += 1
      }
    }

    return workingDays
  }, [
    countPublicHoliday,
    deliveryPeriodConfig,
    delivery_period,
    purchaseBeforeConfig,
    cart,
    countPublicHoliday,
    startDateTime
  ])

  const deliveryDate = useMemo(() => {
    return moment(
      startDateTime + countWorkingDays * 24 * 60 * 60 * 1000
    ).format(format ?? FORMAT)
  }, [startDateTime, countWorkingDays])

  const isPublicHoliday = ({
    workingDays,
    region,
    date
  }: {
    workingDays: number
    region?: string
    date?: Date
  }) => {
    const today = date ? new Date(date) : new Date()

    if (data && region) {
      const publicHolidayRegion = data.data?.filter(
        (publicHoliday) => publicHoliday.region === region
      )

      const regionDays = mapToDayPublicHolidays(
        publicHolidayRegion,
        new Date(today).getFullYear()
      )

      const ispublicHolidayRegion = checkIsPublicHoliday(
        today,
        regionDays,
        workingDays
      )

      if (ispublicHolidayRegion) {
        return ispublicHolidayRegion
      }
    } else if (data) {
      const publicHolidayRegionAll = data.data?.filter(
        (publicHoliday) => publicHoliday.region === 'all'
      )

      const regionDays = mapToDayPublicHolidays(
        publicHolidayRegionAll,
        new Date(today).getFullYear()
      )

      const ispublicHolidayRegion = checkIsPublicHoliday(
        today,
        regionDays,
        workingDays
      )

      if (ispublicHolidayRegion) {
        return ispublicHolidayRegion
      }
    }

    return false
  }

  return {
    countPublicHoliday,
    isPublicHoliday,
    isWeekend,
    startDateTime,
    countWorkingDays,
    deliveryDate
  }
}

export default useDeliveryDate
